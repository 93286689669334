<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        Analytics
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped>

</style>